.textarea-container {
  height: 100px;
  padding: 15px;
}
@import url("https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,100..700;1,100..700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,100..700;1,100..700&display=swap");

.register-main-container {
  padding: 25px 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}
.register-iamge-wrapper {
  width: 100%;
}
.register-image {
  width: 100%;
  height: auto;
}
.register-inner-form-wrapper {
  width: 100%;
  text-align: center;
}
.register-inner-form-wrapper p {
  font-size: 1.6em;
  margin-top: 15px;
  font-family: "Josefin Slab", serif;
  color: #000;
}

.register-form-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 15px;
}
.register-main-text-input {
  background-color: rgb(243, 238, 238);
  height: 55px;
  width: 100%;
  margin-top: 15px;
  border-radius: 10px;
  font-family: "Josefin Slab", serif;
  font-size: 1.3em;
  font-weight: bold;
  padding: 0 15px;
  border: solid 0.5px rgb(218, 206, 206);
}
.success-message {
  color: green !important;
  font-weight: bold;
}
.register-main-form-btn {
  background-color: rgb(16, 4, 91);
  height: 55px;
  margin-top: 25px;
  border-radius: 10px;
  border: solid 0.5px rgb(18, 2, 86);
  color: #fff;
  font-size: 1.6em;
  cursor: pointer;
}
.register-form-wrapper label p {
  color: red;
  text-align: left;
  font-size: 1.2em;
  margin-bottom: -10px;
  font-family: "Josefin Slab", serif;
}

@media (min-width: 475px) {
  .register-inner-form-wrapper {
    width: 80%;
    text-align: center;
  }
}
@media (min-width: 640px) {
  .register-inner-form-wrapper {
    width: 80%;
    text-align: center;
  }
}

@media (min-width: 768px) {
  .register-inner-form-wrapper {
    width: 60%;
    text-align: center;
  }
}

@media (min-width: 1024px) {
  .register-inner-form-wrapper {
    width: 60%;
    text-align: center;
  }
  .register-main-container {
    padding: 25px 5%;
    display: flex;
    flex-direction: row;
  }
  .register-image {
    width: 80%;
    height: auto;
  }
}

@media (min-width: 1280px) {
  .register-iamge-wrapper {
    width: 80%;
  }
  .register-inner-form-wrapper {
    width: 65%;
    text-align: left;
  }
  .register-form-wrapper {
    width: 80%;
  }
  .register-image {
    width: 80%;
  }
}

@media (min-width: 1536px) {
}
